export class DefinicaoEquipamentoMedicaoModel {
  constructor({
    grandezaFisicaId,
    unidadeMedidaId,
    valorNominalMedicao,
    toleranciaMedicao,
  }) {
    this.grandezaFisicaId = grandezaFisicaId;
    this.unidadeMedidaId = unidadeMedidaId;
    this.valorNominalMedicao = valorNominalMedicao;
    this.toleranciaMedicao = toleranciaMedicao;
  }
}
