<template>
  <div>
    <div class="d-flex mb-8">
      <div class="titulo-pagina">
        {{ $t('modulos.definicao_equipamento_medicao.titulos.listagem') }}
      </div>
    </div>
    <v-form
      ref="form"
      class="row"
    >
      <input-grandeza-fisica
        v-model="grandezaFisica"
        class="col-12 col-md-3"
        :label="$t('modulos.definicao_equipamento_medicao.formulario.grandeza_fisica')"
        obrigatorio
        :multiplos="false"
      />
      <input-unidade-medida
        :key="keyAtualiza"
        v-model="unidadeMedida"
        class="col-12 col-md-3"
        :label="$t('modulos.definicao_equipamento_medicao.formulario.unidade_medida')"
        :multiplos="false"
        obrigatorio
        :busca-unidade-menida-grandeza-fisica="true"
        :id-grandeza-fisica="form.grandezaFisicaId"
        :disabled="!form.grandezaFisicaId"
      />
      <input-text
        v-model="form.valorNominalMedicao"
        class="col-12 col-md-3"
        :label="$t('modulos.definicao_equipamento_medicao.formulario.valor_nominal_medicao')"
        obrigatorio
        type="number"
      />
      <input-text
        v-model="form.toleranciaMedicao"
        class="col-12 col-md-2"
        :label="$t('modulos.definicao_equipamento_medicao.formulario.tolerancia_medicao')"
        obrigatorio
        type="number"
      />
      <div class="col-12 col-md-1 d-flex mt-9">
        <botao-padrao
          @click="listar"
        >
          <icone-padrao
            :icone="'$search'"
            :tooltip="$t('geral.botoes.nova_pesquisa')"
          />
        </botao-padrao>
      </div>
    </v-form>
    <div class="mt-4">
      <tabela-padrao
        :dados="tabela.dados"
        class="mt-2"
        :colunas="tabela.colunas"
        :por-pagina="-1"
        sem-acoes
        sem-paginacao
        :mostrar-checkbox="false"
      />
    </div>

    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <dropdown-padrao-item
        outlined
        color="secondary"
        class="mr-2"
        @click="limparCampos"
      >
        {{ $t('geral.botoes.nova_pesquisa') }}
      </dropdown-padrao-item>
    </div>
  </div>
</template>
<script>
import DefinicaoEquipamentoMedicaoService from '@common/services/cadastros/DefinicaoEquipamentoMedicaoService.js';
import { DefinicaoEquipamentoMedicaoModel } from '@common/models/cadastros/DefinicaoEquipamentoMedicaoModel.js';
import { InputUnidadeMedida, InputGrandezaFisica } from '@components/inputs';
import _ from 'lodash';
import helpers from '@/common/utils/helpers';

export default {
  components: { InputUnidadeMedida, InputGrandezaFisica },
  props: ['id'],
  data() {
    return {
      grandezaFisica: null,
      unidadeMedida: null,
      valido: false,
      keyAtualiza: 1,
      form: new DefinicaoEquipamentoMedicaoModel({}),
      opcoes: {
        enumArredondamento: [],
      },
      tabela: {
        dados: [],
        colunas: [
          {
            value: 'codigoTag',
            text: this.$t('modulos.definicao_equipamento_medicao.tabela.codigo_tag'),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t('modulos.definicao_equipamento_medicao.tabela.tipo_instrumento'),
            sortable: false,
          },
        ],
      },
    };
  },
  computed: {
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t(
        'modulos.definicao_equipamento_medicao.validacoes.formulario_invalido'
      );
    },

  },
  watch: {
    'grandezaFisica': function (valor) {
      if (valor?.value) {
        this.form.grandezaFisicaId = valor.value;
        this.unidadeMedida = null
        this.form.unidadeMedidaId = null
        this.keyAtualiza++
      }
    },
    'unidadeMedida': function (valor) {
      if (valor?.value) this.form.unidadeMedidaId = valor.value;
    },
  },
  async mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.definicao_equipamento_medicao.titulos.listagem')
    );
  },
  created(){
    helpers.redirecionarSemPermissao(this, 'DefinicaoEquipamentoMedicao', 'Listar');
  },
  methods: {
    listar: _.debounce(function () {
      if (!this.$refs.form.validate()) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      DefinicaoEquipamentoMedicaoService.listar(this.form)
        .then((res) => {
          this.tabela.dados = res.data.items;
        })
        .catch(() => {
          this.toastErro(
            this.$t('modulos.definicao_equipamento_medicao.erros.id_invalido')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    }, 500),
    limparCampos: function () {
      this.grandezaFisica= null
      this.unidadeMedida = null
      this.form.valorNominalMedicao = null
      this.form.toleranciaMedicao = null

      this.valido = true
    },
  },
};
</script>
